import React, {useEffect} from 'react';
import './ResetPasswordSuccess.scss';
import IcResetLinkSent from '@icons/ic-reset-success.svg';
import { navigate } from 'gatsby';

function ResetPasswordSuccess() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);
  return (
    <div
      id="reset-password-success"
      className="container py-5 d-flex flex-column align-items-center justify-content-center">
      <img src={IcResetLinkSent} />
      <div className="dosis-title-1 text-philadelphia-blue mb-2">
        Reset Success
      </div>
      <div className="asap-body mb-3">
        Your account password has been successfully changed. 
      </div>
      <button className="button-md button-primary" onClick={() => navigate('/login')}>
        Login Now
      </button>
    </div>
  );
}

export default ResetPasswordSuccess;
